import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const RealtorsAdvantages = () => {
  const data = useStaticQuery(graphql`
    query {
      floorPlanHouse: file(
        relativePath: { eq: "pexels-andrew-wilus-5537592.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personWithFloorPlan: file(
        relativePath: { eq: "pexels-karolina-grabowska-4491475.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout maxWidth={600}>
      <SEO 
      title="How to increase your revenue with floor plans: a practical guide." 
      description="Floor plans are an important tool for any Real Estate Proffesional. 
                   Learn how to use them effectively to increase your sales and performance."
      // image={data.personWithFloorPlan.childImageSharp}
      />
      <div style={{ padding: "0px 1em" }}>
        <h1>
          How to increase your revenue with floor plans: a practical guide.
        </h1>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.floorPlanHouse.childImageSharp}
        />
        <p>
          Floor plans are a crutial feature for any real estate listing. Full
          stop. They present features that can't be easily viewed in a picture
          (such as depth of a wardrobe), and to unequivocally determine size &
          layout of the apartment. More importantly, they are highly desired by
          clients and can increase the value of the sold property.
        </p>
        <p>
          A{" "}
          <OutboundLink href="https://www.rightmove.co.uk/news/articles/property-news/maximise-chances-finding-buyer-floorplan/">
            study
          </OutboundLink>{" "}
          from Rightmove states that:
        </p>
        <ul>
          <li>
            one in three buyers were less likely to enquire about a property
            without a floor plan
          </li>
          <li>
            one in five buyers would ignore the listing compeletely (and revisit
            only when nothing else caught their eye)
          </li>
          <li>
            one in ten buyers would not arrange a viewing when a floor is absent
          </li>
        </ul>
        <p>
          These figures speak for themselves. If you are an estate agent missing
          on floor plans, you might consider giving them a shot. They will
          increase the number of leads, shorten the time in which the property
          is sold and most importantly, attract out-of-town buyers. With
          PanoRoom, it is exceptionally easy to improve your listings. Download
          our app, make the first scan, and post it right with your listing.
        </p>
        <h2>… all right, but I'm a Real Estate Photographer!</h2>
        <p>
          Floor plans have a slightly different value for the real estate
          photographers. While their price still reflects the value of the
          property, they are a part of the marketing package you offer to either
          an owner or a broker. Because the apartment you have photographed &
          scanned will be sold quicker and for a higher price, this has huge
          implications on your business.
        </p>
        <p>
          First, extending your range of services will make you stand out from
          other photographers. If you already started oferring more than just
          basic shots, you probably have an idea how about how it influences the
          number of customers. The same applies to the floor plans: especially
          given that they are listed as the 3rd most important feature (right
          after the photos and description) by the NAR (National Association of
          Realtors,
          <a href="https://www.nar.realtor/sites/default/files/documents/2020-generational-trends-report-03-05-2020.pdf">
            source
          </a>
          ).
        </p>
        <p>
          Secondly, speaking pragmatically, the floor plans will allow you to
          either charge more for existing marketing packages, or to create
          add-ons which will give you extra cash from each photoshoot. Given a
          finite number of leads and time slots each month, it is one of the
          most sensible ways to grow your business with little effort.
        </p>

        <h2>Sounds interesting, but how do I start?</h2>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.personWithFloorPlan.childImageSharp}
        />
        <p>
          First and foremost: just start offering them by posting the floor plan
          making service on your website. We created a fantastic marketing
          package, which will show your business as trendy and tech-aware. The
          clients will recognize the new service, and you'll be able to remind
          them of it once they contact you.
        </p>

        <p>
          Once you get their attention, download our app and start making plans.
          Our price per month stays the same no matter how many you are willing
          to create. Also, unlike many competitors, our results are available
          immediately - you can send them to the client right after the
          photoshoot.
        </p>

        <h2>Fingers crossed 🤞</h2>
        <p>
          We hope that our add-on service will help you grow your business. In
          case you have any questions or just want to casually talk about your
          needs, we are there for you - just click the contact tab. Happy
          PanoRooming!
        </p>
      </div>
    </Layout>
  );
};

export default RealtorsAdvantages;
